export const customerSupportInformationMessages = {
  title: 'Tiêu đề',
  icon: 'Biểu tượng',
  isNew: 'New',
  format: 'Loại thông tin',
  status: 'Trạng thái',
  create: 'Tạo thông tin hỗ trợ khách hàng',
  update: 'Cập nhật thông tin hỗ trợ khách hàng',
  content: 'Nội dung',
  uploadExcelTable: 'Tải file excel nội dung',
  statusEnum: {
    active: 'Hoạt động',
    inactive: 'Không hoạt động'
  },
  invalidUrl: 'Đường dẫn không khả dụng',
  findByTitle: 'Tìm theo tiêu đề',
  changeIcon: 'Đổi biểu tượng',
  checkNew: 'Đánh dấu New',
  period: 'Phiên',
  session: 'Kỳ',
  recordDate: 'Ngày ghi chỉ số',
  collectionDate: 'Ngày thu tiền',
  paymentPeriod: 'Thời hạn thanh toán',
  powerCutPeriod: 'Kỳ hạn cắt điện',
  serialNumber: 'STT',
  locationName: 'Điểm giao dịch',
  address: 'Địa chỉ',
  district: 'Quận/Huyện',
  phoneNumber: 'Điện thoại',
  category: 'Danh mục'
};
