import { Button, Progress } from 'antd';
import { RcFile } from 'antd/es/upload';
import { AxiosProgressEvent } from 'axios';
import { notificationsMessages } from 'messages/notification.messages';
import { useState } from 'react';
import { useDeleteFileMutation, useUploadFileMutation } from 'services';
import { FileDto } from 'types';
import { FilesBucketNames } from 'utils';
import message from '../message';
import Avatar, { AvatarProps } from './Avatar';
import { PlusIcon } from 'assets';
import { Upload } from '../upload';

type AvatarUploadOnlyProps = AvatarProps & {
  onUploadSuccess?: (value: FileDto) => void;
};
export default function AvatarUploadOnlyCrop({
  onUploadSuccess,
  size = 48,
  isLoading,
  ...props
}: AvatarUploadOnlyProps) {
  const [fileId, setFileId] = useState(props.fileId);
  const [progress, setProgress] = useState<number | undefined>();
  const [onUpload, { isLoading: isLoadingUpload }] = useUploadFileMutation();

  const onChangeProgressHandle = (progressEvent: AxiosProgressEvent) => {
    const percent = progressEvent.progress ?? 0;
    setProgress(percent * 100);
  };
  const [onDelete] = useDeleteFileMutation();

  const handleCrop = (file: File) => {
    if (file) {
      onUpload({
        bucketName: FilesBucketNames.PUBLIC,
        file,
        onUploadProgress: onChangeProgressHandle
      })
        .unwrap()
        .then((rs) => {
          onUploadSuccess?.(rs.data);
          setProgress(0);
          setFileId(rs.data.fileId);
          message.systemSuccess(notificationsMessages.updateAvatarSuccess);
          if (props.fileId) {
            onDeleteHandle();
          }
        });
    }
  };
  const onDeleteHandle = () => {
    if (props.fileId) {
      onDelete(props.fileId)
        .unwrap()
        .then((rs) => {
          setProgress(0);
        });
    }
  };
  return (
    <div
      className='relative inline-block'
      style={{
        width: size,
        height: size
      }}
    >
      <Upload.Crop
        onChange={({ file }) => {
          handleCrop(file as RcFile);
        }}
        showUploadList={false}
        customRequest={() => true}
        name='avatar'
        multiple={false}
      >
        {isLoadingUpload && (
          <Progress
            className='absolute left-[-4px] top-[-4px]'
            type='circle'
            size={size + 8}
            percent={progress}
            strokeWidth={4}
            format={() => ''}
          />
        )}
        <>
          {fileId || props.name ? (
            <Avatar isLoading={Boolean(isLoading || isLoadingUpload)} {...props} fileId={fileId} size={size} />
          ) : (
            <Button shape='circle' type='dashed' style={{ width: size, height: size }}>
              <PlusIcon />
            </Button>
          )}
        </>
        <div className='absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-colorBgContainerHover text-white opacity-0 transition-opacity duration-300 hover:opacity-100'></div>
      </Upload.Crop>
    </div>
  );
}
