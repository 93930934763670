import OriginAvatar from './Avatar';
import AvatarWithUpdate from './WithUpdate';
import AvatarWithReplace from './WithReplace';

export type AvatarProps = typeof OriginAvatar & {
  WithUpdate: typeof AvatarWithUpdate;
  WithReplace: typeof AvatarWithReplace;
};
const Avatar = OriginAvatar as AvatarProps;

Avatar.WithUpdate = AvatarWithUpdate;
Avatar.WithReplace = AvatarWithReplace;

export { Avatar };
