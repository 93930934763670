import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ThreeDots } from 'assets';
import { messages } from 'messages';
import { ApiSharingPartnerDto } from 'types';

type ApiSharingPartnerActionProps = {
  apiSharingPartner: ApiSharingPartnerDto;
  onDelete?: (apiSharingPartner: ApiSharingPartnerDto) => void;
  onUpdateStatus?: (apiSharingPartner: ApiSharingPartnerDto) => void;
  onUpdateInfo?: (apiSharingPartner: ApiSharingPartnerDto) => void;
};

const ApiSharingPartnerActions = ({
  apiSharingPartner,
  onUpdateInfo,
  onDelete,
  onUpdateStatus
}: ApiSharingPartnerActionProps) => {
  const actionItems: ItemType[] = [
    {
      key: 'updateInfo',
      label: messages.updateInfo
    },
    {
      key: 'updateStatus',
      label: messages.updateStatus
    },
    {
      key: 'delete',
      danger: true,
      label: messages.deleteButtonText
    }
  ];

  const onClickActionItem = (action: string, data: ApiSharingPartnerDto) => {
    switch (action) {
      case 'updateInfo':
        onUpdateInfo?.(data);
        break;
      case 'updateStatus':
        onUpdateStatus?.(data);
        break;
      case 'delete':
        onDelete?.(data);
        break;
    }
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{
          items: actionItems,
          onClick: ({ key }) => {
            onClickActionItem(key, apiSharingPartner);
          }
        }}
      >
        <Button type='text' shape='circle'>
          <ThreeDots />
        </Button>
      </Dropdown>
    </>
  );
};

export default ApiSharingPartnerActions;
