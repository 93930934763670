import { FormInstance, UploadFile } from 'antd';
import { FormItem, UploadExcel } from 'components';
import { UploadExcelDataType } from 'components/common/excel/UploadExcel';
import { CreateCustomerSupportInformationDto } from 'types';
import { customerSupportInformationValidationRules } from 'utils';
import { CustomerSupportInformationFormType } from './CustomerSupportInformationForm';
import { customerSupportInformationMessages } from 'messages';
type Props = {
  contentContainer?: HTMLDivElement | null;
  form: FormInstance<CustomerSupportInformationFormType>;
  isPreview?: boolean;
};
const CustomerSupportInformationContentFormatTable = ({ form, contentContainer, isPreview }: Props) => {
  const normFileExcel = ({ file }: { file: UploadFile<UploadExcelDataType> }) => {
    if (file.percent === 100 && file.response) {
      form.setFieldValue('file', file.response.file);
      return JSON.stringify(file.response.excelData);
    }
    return file;
  };

  return (
    <>
      <FormItem<CreateCustomerSupportInformationDto>
        label={customerSupportInformationMessages.content}
        valuePropName='fileList'
        name='content'
        getValueFromEvent={normFileExcel}
        rules={customerSupportInformationValidationRules.content}
        hiddenLabel
      >
        <UploadExcel
          excelProps={{
            minWidth: 720,
            height: window.innerHeight - 160,
            maxWidth: contentContainer?.offsetWidth ? contentContainer?.offsetWidth - 48 : 1060
          }}
          onRemove={() => form.setFieldValue('content', undefined)}
          isPreview={isPreview}
        />
      </FormItem>
      <FormItem name='file' noStyle />
    </>
  );
};
export default CustomerSupportInformationContentFormatTable;
