export const apiSharingPartnersMessages = {
  create: 'Tạo mới cấu hình',
  update: 'Cập nhật cấu hình',
  delete: 'Xóa cấu hình chia sẻ API',
  confirmDelete: 'Bạn có chắc chắn muốn xóa cấu hình này không?',
  name: 'Tên',
  findByName: 'Tìm theo tên',
  description: 'Mô tả',
  code: 'Customer code',
  apiKey: 'API key',
  permissions: 'API sử dụng',
  status: 'Trạng thái'
};
