export const systemConfigsMessages = {
  ratingSystem: 'Hệ thống đánh giá',
  callCenterSystem: 'Hệ thống tổng đài',
  ratingSystemApiUrl: 'API URL',
  ratingSystemApiKey: 'API key',
  noValue: 'Chưa có',
  updateRatingSystem: 'Cập nhật hệ thống đánh giá',
  callCenterSystemApiUrl: 'API URL',
  callCenterSystemDescription: 'Mô tả',
  connectCallCenterSystem: 'Kết nối hệ thống tổng đài'
};
