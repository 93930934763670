import { Spin, Button, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ExcelFileIcon } from 'assets';
import { Select, Table } from 'components/common';
import dayjs from 'dayjs';
import { useElectricalRecordingSchedulePaging } from 'hooks/customer-support-information';
import { useFile } from 'hooks/files';
import { customerSupportInformationMessages, messages } from 'messages';
import { twMerge } from 'tailwind-merge';
import { ElectricalRecordingScheduleDto } from 'types';
import { DATE_FORMAT, fileServerDownload } from 'utils';

type ElectricalRecordingSchedulesProps = {
  showExport?: boolean;
  showSearch?: boolean;
  fileExcelId?: number;
};

const ElectricalRecordingSchedules = ({
  showExport = true,
  showSearch = true,
  fileExcelId
}: ElectricalRecordingSchedulesProps) => {
  const [form] = Form.useForm();
  const month = Form.useWatch('month', form);
  const year = Form.useWatch('year', form);

  const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
  const currentYear = dayjs().year();
  const yearOptions = Array.from({ length: currentYear - 2012 + 1 }, (_, i) => i + 2012);

  const {
    electricalRecorindSchedules,
    isLoading: isLoadingContent,
    handleChangePage,
    count,
    pageIndex
  } = useElectricalRecordingSchedulePaging({
    month,
    year
  });

  const { file } = useFile(fileExcelId);

  const columns: ColumnsType<ElectricalRecordingScheduleDto> = [
    {
      title: customerSupportInformationMessages.period,
      dataIndex: 'numberOfPeriods',
      render: (value) => value ?? '-'
    },
    {
      title: customerSupportInformationMessages.session,
      dataIndex: 'sessionNumber',
      render: (value) => value ?? '-'
    },
    {
      title: customerSupportInformationMessages.recordDate,
      dataIndex: 'recordDate',
      render: (value) => (value ? dayjs(value).format(DATE_FORMAT) : '-')
    },
    {
      title: customerSupportInformationMessages.collectionDate,
      dataIndex: 'collectionDate',
      render: (value) => (value ? dayjs(value).format(DATE_FORMAT) : '-')
    },
    {
      title: customerSupportInformationMessages.paymentPeriod,
      dataIndex: 'paymentPeriod',
      render: (value) => (value ? dayjs(value).format(DATE_FORMAT) : '-')
    },
    {
      title: customerSupportInformationMessages.powerCutPeriod,
      dataIndex: 'powerCutPeriod',
      render: (value) => (value ? dayjs(value).format(DATE_FORMAT) : '-')
    }
  ];

  return (
    <div className='w-full'>
      <div className={twMerge('relative left-1/2 mb-5 flex h-fit -translate-x-1/2 items-center rounded-lg')}>
        {showSearch && (
          <Form form={form} layout='inline' initialValues={{ month: dayjs().month() + 1, year: dayjs().year() }}>
            <Form.Item name='month'>
              <Select
                placeholder={messages.month}
                options={monthOptions.map((option) => ({ label: option, value: option }))}
              />
            </Form.Item>
            <Form.Item name='year'>
              <Select
                placeholder={messages.year}
                options={yearOptions.map((option) => ({ label: option, value: option }))}
              />
            </Form.Item>
          </Form>
        )}
        {showExport && (
          <Button
            type='link'
            className='ml-auto h-10 font-normal text-colorTextBase'
            icon={<ExcelFileIcon className='h-4 w-4' />}
            disabled={!file?.url}
            onClick={() => {
              if (file) {
                fileServerDownload(file);
              }
            }}
          >
            {messages.exportExcel}
          </Button>
        )}
      </div>
      <Spin spinning={isLoadingContent}>
        <Table
          columns={columns}
          count={count}
          dataSource={electricalRecorindSchedules}
          currentPage={pageIndex}
          handleChangePage={handleChangePage}
        />
      </Spin>
    </div>
  );
};

export default ElectricalRecordingSchedules;
