import { Button, Checkbox, Divider, Form, FormInstance, Input, Spin, Typography } from 'antd';
import { ButtonOptions, FormItem, message, Upload } from 'components';
import { messages, validateMessages } from 'messages';
import { customerSupportInformationMessages } from 'messages/customer-support-information.messages';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
  useCreateCustomerSupportInformationMutation,
  useDeleteFileMutation,
  useGetCustomerSupportInformationDetailQuery,
  useUpdateCustomerSupportInformationMutation,
  useUpdateFileMutation,
  useUploadElectricalRecordingSchedulesMutation,
  useUploadElectricityPaymentLocationsMutation,
  useUploadFileMutation
} from 'services';
import { CreateCustomerSupportInformationDto } from 'types';
import {
  CORE_OPTION_TYPES,
  createCustomerSupportInformationInitialValues,
  CUSTOMER_SUPPORT_INFORMATION_FORMAT,
  customerSupportInformationValidationRules,
  FilesBucketNames,
  MICROSERVICES,
  updateCustomerSupportInformationInitialValues
} from 'utils';
import CustomerSupportInformationContentFormatLink from './CustomerSupportInformationContentFormatLink';
import CustomerSupportInformationContentFormatTable from './CustomerSupportInformationContentFormatTable';
import CustomerSupportInformationContentFormatText from './CustomerSupportInformationContentFormatText';
import { CUSTOMER_SUPPORT_DEFAULT_ICONS } from 'utils/constants/customer-support-information-default-icons.constant';
import { twMerge } from 'tailwind-merge';
import CustomerSupportInformationExcel from './CustomerSupportInformationExcel';
import { CloseIcon, ExcelFileIcon } from 'assets';
import { ServerIconRefProps } from 'components/common/upload/ServerIcon';
import { RcFile } from 'antd/es/upload';
import ElectricalRecordingSchedules from './ElectricalRecodingSchedules';
import ElectricityPaymentLocations from './ElectricityPaymentLocations';

export type CustomerSupportInformationFromProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  customerSupportInformationId?: number;
  className?: string;
};
export type CustomerSupportInformationFormType = CreateCustomerSupportInformationDto & {
  file?: RcFile;
};

export type CustomerSupportInformationFromRefProps = {
  form: FormInstance<CustomerSupportInformationFormType>;
  isLoading: boolean;
};
const DATA_EXCEL_CHECK = '[]';
const CustomerSupportInformationForm = forwardRef<
  CustomerSupportInformationFromRefProps,
  CustomerSupportInformationFromProps
>(({ onChangeLoading, onCreateSuccess, customerSupportInformationId, className }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));

  const [form] = Form.useForm<CustomerSupportInformationFormType>();
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const uploadIconRef = useRef<ServerIconRefProps>(null);

  const icon = Form.useWatch('icon', form);
  const content = Form.useWatch('content', form);

  const { data: customerSupportInformation, isLoading: isLoadingDetail } = useGetCustomerSupportInformationDetailQuery(
    customerSupportInformationId!,
    {
      skip: !customerSupportInformationId,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (customerSupportInformation && customerSupportInformationId) {
      form.setFieldsValue({
        ...customerSupportInformation.data,
        icon: customerSupportInformation.data.icon,
        content: [
          CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE,
          CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICAL_RECORDING_SCHEDULE,
          CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICITY_PAYMENT_LOCATION
        ].includes(customerSupportInformation.data.formatId)
          ? DATA_EXCEL_CHECK
          : customerSupportInformation.data.content
      });
    }
  }, [customerSupportInformation, customerSupportInformationId]);

  const [onCreate, { isLoading: isLoadingCreate }] = useCreateCustomerSupportInformationMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateCustomerSupportInformationMutation();
  const [onUpload, { isLoading: isLoadingUpload }] = useUploadFileMutation();
  const [onUpdateFile, { isLoading: isLoadingUpdateFile }] = useUpdateFileMutation();
  const [onDeleteFile] = useDeleteFileMutation();
  const [onUploadElectricalRecordingShedule, { isLoading: isLoadingUploadElectricalRecordingSchedule }] =
    useUploadElectricalRecordingSchedulesMutation();
  const [onUploadElectricityPaymentLocation, { isLoading: isLoadingUploadElectricityPaymentLocation }] =
    useUploadElectricityPaymentLocationsMutation();

  const onFinish = async ({ file, ...values }: CustomerSupportInformationFormType) => {
    const data: CreateCustomerSupportInformationDto = {
      ...values
    };
    let fileId: number | undefined = undefined;
    if (file) {
      switch (customerSupportInformation?.data?.formatId) {
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE: {
          if (customerSupportInformation?.data.fileId) {
            const response = await onUpdateFile({
              fileId: customerSupportInformation.data.fileId,
              file: file
            });
            if (response.error) {
              return;
            }
            fileId = response.data?.data.fileId;
          } else {
            const response = await onUpload({
              bucketName: FilesBucketNames.PUBLIC,
              file
            });
            if (response.error) {
              return;
            }
            fileId = response.data?.data.fileId;
          }
          break;
        }
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICAL_RECORDING_SCHEDULE: {
          const response = await onUploadElectricalRecordingShedule(file);
          if (response.error) {
            return;
          }
          break;
        }
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICITY_PAYMENT_LOCATION: {
          const response = await onUploadElectricityPaymentLocation(file);
          if (response.error) {
            return;
          }
          break;
        }
      }
    }

    if (!customerSupportInformationId) {
      onCreate({ ...data, fileId: fileId })
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    } else {
      let content = data.content;
      switch (data.formatId) {
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE:
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICITY_PAYMENT_LOCATION:
        case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICAL_RECORDING_SCHEDULE:
          if (data.content === DATA_EXCEL_CHECK) content = undefined;
          break;
        default:
          content = data.content;
      }
      onUpdate({
        customerSupportInformationId,
        ...data,
        fileId: fileId,
        content: content
      })
        .unwrap()
        .then((rs) => {
          if (customerSupportInformation?.data.icon && customerSupportInformation?.data.icon !== data.icon) {
            onDeleteFile(customerSupportInformation?.data.icon).unwrap();
          }
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    }
  };

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(
        isLoadingCreate ||
          isLoadingUpdate ||
          isLoadingUpload ||
          isLoadingUpdateFile ||
          isLoadingUploadElectricalRecordingSchedule ||
          isLoadingUploadElectricityPaymentLocation
      );
    }
  }, [
    onChangeLoading,
    isLoadingCreate,
    isLoadingUpdate,
    isLoadingUpload,
    isLoadingUpdateFile,
    isLoadingUploadElectricalRecordingSchedule,
    isLoadingUploadElectricityPaymentLocation
  ]);

  return (
    <div ref={contentContainerRef}>
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name=''
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={
          customerSupportInformationId
            ? updateCustomerSupportInformationInitialValues
            : createCustomerSupportInformationInitialValues
        }
        className={twMerge('flex w-full justify-center', className)}
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <div className='flex w-full flex-col items-center'>
            <div className='w-[720px]'>
              <div>
                <Typography.Title className='mb-5 text-lg'>{messages.general}</Typography.Title>
                <div className='mb-6'>
                  <div className='flex gap-4'>
                    <div className='h-14'>
                      <FormItem<CustomerSupportInformationFormType>
                        name='icon'
                        rules={customerSupportInformationValidationRules.icon}
                        noStyle
                      >
                        <Upload.ServerIcon
                          ref={uploadIconRef}
                          fileId={icon}
                          iconItems={CUSTOMER_SUPPORT_DEFAULT_ICONS}
                          onChange={(fileId) => {
                            form.setFieldValue('icon', fileId);
                          }}
                          size={56}
                          shape='square'
                        />
                      </FormItem>
                    </div>

                    <FormItem.FloatLabel<CustomerSupportInformationFormType>
                      name='title'
                      label={customerSupportInformationMessages.title}
                      rules={customerSupportInformationValidationRules.title}
                      className='mb-0 w-full'
                      noStyle
                    >
                      <Input />
                    </FormItem.FloatLabel>
                  </div>
                  <FormItem.ErrorText fieldsName={['icon', 'title']} form={form} />
                </div>
                <Button
                  onClick={() => uploadIconRef.current && uploadIconRef.current.setOpen(true)}
                  type='link'
                  className='font-normal no-underline'
                >
                  {customerSupportInformationMessages.changeIcon}
                </Button>
                <Form.Item<CustomerSupportInformationFormType>
                  rules={customerSupportInformationValidationRules.isNew}
                  name='isNew'
                  valuePropName='checked'
                  className='mb-0 mt-4'
                >
                  <Checkbox>{customerSupportInformationMessages.checkNew}</Checkbox>
                </Form.Item>
              </div>
              <Divider className='mb-8 mt-6' />
              <div>
                <Typography.Title className='mb-5 text-lg'>
                  {customerSupportInformationMessages.content}
                </Typography.Title>

                {customerSupportInformation?.data.format ? (
                  <div className='flex'>
                    <div
                      className={twMerge(
                        'h-9 min-w-[112px] items-center justify-center rounded-full border border-none bg-colorTextLabel px-4 py-2 text-center text-white opacity-70'
                      )}
                    >
                      <span className={twMerge('text-center text-sm font-semibold')}>
                        {customerSupportInformation.data.format.name}
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormItem<CustomerSupportInformationFormType>
                    name='formatId'
                    label={customerSupportInformationMessages.format}
                    hiddenLabel
                    rules={customerSupportInformationValidationRules.formatId}
                    className='mb-0 h-fit w-full'
                    initialValue={CUSTOMER_SUPPORT_INFORMATION_FORMAT.TEXT}
                  >
                    <ButtonOptions
                      optionTypeId={CORE_OPTION_TYPES.CUSTOMER_SUPPORT_INFORMATION_FORMAT}
                      service={MICROSERVICES.CORE}
                      onChange={() => form.setFieldValue('content', undefined)}
                      disabled={!!customerSupportInformationId}
                    />
                  </FormItem>
                )}
              </div>
            </div>

            <div className='mt-8 min-w-[720px]'>
              <Form.Item
                shouldUpdate={(prev: CustomerSupportInformationFormType, curr: CustomerSupportInformationFormType) =>
                  prev.formatId !== curr.formatId
                }
                noStyle
              >
                {() => {
                  const formatId: CUSTOMER_SUPPORT_INFORMATION_FORMAT = form.getFieldValue('formatId');
                  switch (formatId) {
                    case CUSTOMER_SUPPORT_INFORMATION_FORMAT.TEXT:
                      return (
                        <div>
                          <CustomerSupportInformationContentFormatText form={form} initialValue={''} />
                        </div>
                      );
                    case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICAL_RECORDING_SCHEDULE:
                      if (customerSupportInformationId && content === DATA_EXCEL_CHECK)
                        return (
                          <div>
                            <div
                              className='relative left-1/2 mb-6 flex h-16 -translate-x-1/2 items-center gap-4 rounded-lg bg-colorBgSmallModal pl-4 pr-[52px]'
                              style={{ width: 720 }}
                            >
                              <ExcelFileIcon />
                              <Typography.Text>{customerSupportInformation?.data?.title}</Typography.Text>
                              <Button
                                onClick={() => {
                                  form.setFieldValue('content', undefined);
                                }}
                                className='absolute right-2 h-8 w-8'
                                shape='circle'
                                type='text'
                                icon={<CloseIcon />}
                              />
                            </div>
                          </div>
                        );
                      if (content !== DATA_EXCEL_CHECK) {
                        return (
                          <CustomerSupportInformationContentFormatTable
                            form={form}
                            contentContainer={contentContainerRef.current}
                            isPreview={false}
                          />
                        );
                      }
                      return <Spin spinning />;
                    case CUSTOMER_SUPPORT_INFORMATION_FORMAT.ELECTRICITY_PAYMENT_LOCATION:
                      if (customerSupportInformationId && content === DATA_EXCEL_CHECK)
                        return (
                          <div>
                            <div
                              className='relative left-1/2 mb-6 flex h-16 -translate-x-1/2 items-center gap-4 rounded-lg bg-colorBgSmallModal pl-4 pr-[52px]'
                              style={{ width: 720 }}
                            >
                              <ExcelFileIcon />
                              <Typography.Text>{customerSupportInformation?.data?.title}</Typography.Text>
                              <Button
                                onClick={() => {
                                  form.setFieldValue('content', undefined);
                                }}
                                className='absolute right-2 h-8 w-8'
                                shape='circle'
                                type='text'
                                icon={<CloseIcon />}
                              />
                            </div>
                          </div>
                        );
                      if (content !== DATA_EXCEL_CHECK) {
                        return (
                          <CustomerSupportInformationContentFormatTable
                            form={form}
                            contentContainer={contentContainerRef.current}
                            isPreview={false}
                          />
                        );
                      }
                      return <Spin spinning />;
                    case CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE:
                      if (customerSupportInformationId && content === DATA_EXCEL_CHECK)
                        return (
                          <div>
                            <div
                              className='relative left-1/2 mb-6 flex h-16 -translate-x-1/2 items-center gap-4 rounded-lg bg-colorBgSmallModal pl-4 pr-[52px]'
                              style={{ width: 720 }}
                            >
                              <ExcelFileIcon />
                              <Typography.Text>{customerSupportInformation?.data?.title}</Typography.Text>
                              <Button
                                onClick={() => {
                                  form.setFieldValue('content', undefined);
                                }}
                                className='absolute right-2 h-8 w-8'
                                shape='circle'
                                type='text'
                                icon={<CloseIcon />}
                              />
                            </div>
                            <CustomerSupportInformationExcel
                              customerSupportInformationId={customerSupportInformationId}
                              showSearch={false}
                              minWidth={720}
                              height={window.innerHeight - 160}
                              maxWidth={
                                contentContainerRef.current?.offsetWidth
                                  ? contentContainerRef.current.offsetWidth - 48
                                  : 1060
                              }
                              showExport={false}
                            />
                          </div>
                        );
                      if (content !== DATA_EXCEL_CHECK) {
                        return (
                          <CustomerSupportInformationContentFormatTable
                            form={form}
                            contentContainer={contentContainerRef.current}
                          />
                        );
                      }
                      return <Spin spinning />;
                    default:
                      return (
                        <div>
                          <CustomerSupportInformationContentFormatLink />
                        </div>
                      );
                  }
                }}
              </Form.Item>
              <Form.Item name='content' label={customerSupportInformationMessages.content} noStyle />
              <Form.Item name='file' noStyle />
            </div>
          </div>
        </Spin>
      </Form>
    </div>
  );
});

export default CustomerSupportInformationForm;
