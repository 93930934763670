export const ticketSamplesMessages = {
  create: 'Tạo mới mẫu',
  update: 'Cập nhật mẫu',
  serviceType: 'Loại dịch vụ',
  title: 'Tên mẫu',
  content: 'Nội dung',
  summary: 'Tóm tắt',
  hotkey: 'Cú pháp nhanh',
  delete: 'Xóa mẫu nội dung',
  confirmDelete: 'Bạn có chắc chắn muốn xóa mẫu nội dung này không?',
  general: 'Thông tin chung'
};
