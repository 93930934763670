


import { createApi } from "@reduxjs/toolkit/query/react";
import { ResponseDto, ResponsePagingDto } from "types";
import { TicketDto, CreateTicketDto } from "types/dto/ticket";
import { FindAllTicketCompletedDto } from "types/dto/ticket/find-all-ticket-completed.dto";
import { FindAllTicketInProgressDto } from "types/dto/ticket/find-all-ticket-in-progress.dto";

import axiosBaseQuery from "utils/base-api";

export const ticketsApi = createApi({
    reducerPath: 'ticketApi',
    tagTypes: ['tickets', 'ticket_detail','appointment_date'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        getTicketsInProgressByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketInProgressDto>({
            query: (params) => ({
                url: '/tickets/dtv/in_progress_by_customer_code',
                method: 'get',
                params
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(({ticketId}) => ({
                        type: 'tickets',
                        id: ticketId
                      }))
                    : ['tickets']    
        }),
        getTicketsCompletedByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketCompletedDto>({
            query: (params) => ({
                url: '/tickets/dtv/completed_by_customer_code',
                method: 'get',
                params
            }),
            providesTags: (result) =>
                result && result.data.rows.length
                    ? result.data.rows.map(({customerCode}) => ({
                        type: 'tickets',
                        id: customerCode
                      }))
                    : ['tickets']       
        }),
        
        getTicketDetail: builder.query<ResponseDto<TicketDto>, number>({
            query: (ticketId) => ({
                url: `/tickets/${ticketId}`,
                method: 'get'
            }),
            providesTags: (result) =>
                result?.data ? [{ type: 'ticket_detail', id: result.data.ticketId}] : []
        }),

        createTicket: builder.mutation<ResponseDto<TicketDto>, CreateTicketDto>({
            query: (newTicket) => ({
                url: '/tickets/dtv/create_and_transfer_to_org_unit',
                method: 'post',
                data: newTicket
            }),
            invalidatesTags: ['tickets']
        }),
        
        getAppointmentDate : builder.query<ResponseDto<string>, string>({
            query: () => ({
                url: 'tickets/assumed_appointment_date',
                method: 'get'
            }),
            providesTags: (result) => 
                result?.data ? [{type: 'appointment_date'}] : []
        })

    })
});

export const {
    useGetTicketsInProgressByCustomerCodeQuery,
    useGetTicketsCompletedByCustomerCodeQuery,
    useCreateTicketMutation,
    useGetTicketDetailQuery,
    useGetAppointmentDateQuery,
} = ticketsApi;





