export const internalChatMessages = {
  send: 'Gửi',
  sent: 'Đã gửi',
  contacts: 'Liên hệ',
  contactName: 'Tên liên hệ',
  internalChat: 'Chat nội bộ',
  keyword: 'Từ khoá',
  name: 'Tên đoạn chat',
  participants: 'Thành viên',
  adminId: 'Quản trị viên',
  createNewGroupConversation: 'Tạo nhóm',
  updateGroupConversation: 'Cập nhật nhóm chat',
  deleteGroup: 'Xóa nhóm',
  isTyping: 'Đang soạn tin',
  forward: 'Chuyển tiếp',
  copy: 'Sao chép',
  copySelectedText: 'Sao chép nội dung đã chọn',
  copyLinkText: 'Sao chép đường dẫn',
  group: 'Nhóm',
  sentImage: 'Đã gửi ảnh',
  sentFile: 'Đã gửi file',
  download: 'Tải xuống',
  you: 'Bạn',
  groupInfo: 'Thông tin nhóm',
  changeGroupName: 'Sửa tên nhóm',
  leaveGroup: 'Rời nhóm',
  groupAdmin: 'Trưởng nhóm',
  manage: 'Quản lý',
  addParticipants: 'Thêm thành viên',
  groupName: 'Tên nhóm',
  confirmDelete: 'Bạn có chắc muốn xóa nhóm chat',
  participated: 'Đã tham gia',
  manageParticipants: 'Quản lý thành viên',
  participantName: 'Tên thành viên',
  deleteParticipant: 'Xóa thành viên',
  promote: 'Chuyển trưởng nhóm',
  confirmLeave: 'Bạn có chắc muốn rời nhóm chat',
  reply: 'Trả lời',
  image: 'Hình ảnh',
  sentToFast: 'Bạn đang gửi tin nhắn quá nhanh! Vui lòng chờ một chút'
};
