export enum AGENT_MAP_OBJECT_TYPE {
  SINGLE_DOOR = 25,
  WALL = 26,
  DOUBLE_DOOR = 52,
  SLIDING_DOOR = 53,

  /** Textbox của seat  ( chỉ trên FE )*/
  SEAT_IP = 1,
  SEAT_NAME = 2,
  SEAT = 3
}
