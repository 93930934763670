import { Button, Form, FormInstance } from 'antd';
import { AgentMapModalInfo } from 'components';
import { AgentMapModalInfoRef } from 'components/agent-map/AgentMaModalInfo';
import { useTitle } from 'hooks';
import { sidebarMenuMessages } from 'messages';
import React, { memo, useRef } from 'react';
import { CreateAgentMapDto } from 'types';
import { FabricAgentMapOtherObject, FabricAgentMapSeat } from 'types/fabric-agent-map';

export type AgentMapFormType = CreateAgentMapDto & {
  fabricSeats: FabricAgentMapSeat[];
  fabricOtherObjects: FabricAgentMapOtherObject[];
};
type AgentMapNameProps = {
  agentMapId: number;
  form: FormInstance;
};
const AgentMapName: React.FC<AgentMapNameProps> = ({ agentMapId, form }) => {
  useTitle(sidebarMenuMessages.agentMaps);

  const infoRef = useRef<AgentMapModalInfoRef>(null);
  const name = Form.useWatch('name', form) || '';

  return (
    <div>
      <Form.Item<AgentMapFormType> name='name' noStyle />
      <Button
        onClick={() => {
          if (infoRef.current) {
            infoRef.current.setFieldsValue({
              name
            });
            infoRef.current.setOpenInfo(true);
          }
        }}
        type='link'
        className='text-lg text-colorTextBase hover:bg-hoverColor2'
      >
        {name}
      </Button>

      <AgentMapModalInfo
        ref={infoRef}
        handleSubmit={(values) => {
          form.setFieldValue('name', values.name);
        }}
      />
    </div>
  );
};

export default memo(AgentMapName);
