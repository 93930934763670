export const MESSAGE_LIMIT_LOAD = 30;
export const MESSAGE_LIMIT_CURRENT = MESSAGE_LIMIT_LOAD * 2;

export const MESSAGE_SPACING_LARGE = 16;
export const MESSAGE_SPACING = 8;
export const MESSAGE_SPACING_END = 20;

export const MESSAGE_SYSTEM_HEIGHT = 30;
export const MESSAGE_IMAGE_HEIGHT_LARGE = 360;
export const MESSAGE_IMAGE_HEIGHT_MEDIUM = 190;
export const MESSAGE_IMAGE_HEIGHT = 126;
export const MESSAGE_FILE_HEIGHT = 64;
export const MESSAGE_DATE_HEIGHT = 52;
export const MESSAGE_SENDER_NAME = 26;

export const MESSAGE_REPLY_HEIGHT = 42;
export const MESSAGE_REPLY_HEIGHT_IN_TEXT = MESSAGE_REPLY_HEIGHT + 4;
export const MESSAGE_REPLY_HEIGHT_IN_IMAGE = MESSAGE_REPLY_HEIGHT + 8 * 2;
export const MESSAGE_REPLY_HEIGHT_IN_FILE = MESSAGE_REPLY_HEIGHT + 8;

export const MESSAGE_TAG_NAME = `tag-name`;
