export const internalAnnouncementsMessages = {
  saved: 'Tin lưu trữ',
  create: 'Tạo mới thông báo',
  update: 'Cập nhật thông báo',
  delete: 'Xóa thông báo',
  confirmDelete: 'Bạn có chắc chắn muốn xóa thông báo này không?',
  internalAnnouncementId: 'Mã quản lý thông báo nội bộ',
  organizationUnitId: 'ID đơn vị/phòng đội',
  organizationUnit: 'Đơn vị',
  title: 'Tiêu đề',
  content: 'Nội dung',
  files: 'Tệp đính kèm',
  isRequestConfirm: 'Yêu cầu xác nhận',
  isRequestConfirmViewed: 'Yêu cầu xác nhận xem thông báo',
  priority: 'Độ ưu tiên',
  statusId: 'ID trạng thái',
  status: 'Trạng thái',
  viewedCount: 'Lượt xem',
  viewedAt: 'Ngày xem',
  confirmedAt: 'Xác nhận ngày',
  confirmedCount: 'Lượt xác nhận',
  createdBy: 'Người đăng',
  createdAt: 'Ngày đăng',
  updatedAt: 'Thời gian cập nhật',
  updateStatus: 'Cập nhật trạng thái',
  currentStatus: 'Trạng thái hiện tại',
  closeAnnouncement: 'Đóng thông báo',
  info: 'Thông tin',
  statistic: 'Thống kê',
  notHasAnnouncement: 'Không có thông báo nào',
  confirmCloseAnnouncement: 'Bạn chưa xác nhận thông báo. Bạn có chắc chắn muốn đóng thông báo này không?',
  confirmUpdate: (quantity?: number) =>
    `Thông báo này đã được ${quantity ?? 0} người xác nhận. Nếu cập nhật thông tin, những người dùng này sẽ phải xác nhận lại thông báo`,
  scrollToBottomToConfirmText: 'Cuộn đến cuối tin và bấm vào nút "Đã xem" để xác nhận',
  confirmWarningText: 'Bằng việc nhấn nút dưới đây, bạn xác nhận đã đọc đầy đủ nội dung của tin nội bộ này.',
  confirmReadingText: 'Xác nhận đã xem',
  confirmAtText: (timestamp: string) => `Đã xác nhận xem tin lúc ${timestamp}`,
  searchByTitle: 'Tìm kiếm theo tiêu đề',
  summary: 'Tổng quan',
  numberOfUserViewed: (count: number) => `${count} người đã xem`,
  viewedAtTime: (timestamp: string) => `Xem lúc ${timestamp}`,
  confirmStatusEnum: {
    confirmed: 'Đã xác nhận',
    notConfirmed: 'Chưa xác nhận'
  },
  deletedAt: 'Thời gian xóa'
};
