export const organizationUnitsMessages = {
  shortTitle: 'Đơn vị',
  name: 'Đơn vị tổ chức',
  code: 'Mã đơn vị',
  organizationUnitName: 'Tên đơn vị',
  organizationUnitQuantity: 'Số lượng',
  createNew: 'Tạo mới đơn vị tổ chức',
  hasParent: 'Thuộc đơn vị/phòng đội cấp cao hơn',
  upperOrganizationUnit: 'Đơn vị trực thuộc',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  service: 'Dịch vụ',
  update: 'Cập nhật đơn vị tổ chức',
  confirmDelete: 'Bạn có chắc chắn muốn xóa đơn vị này không?',
  delete: 'Xóa đơn vị',
  classify: 'Phân loại',
  headUser: 'Trưởng phòng',
  deputyUser: 'Phó phòng',
  parent: 'Đơn vị trực thuộc',
  users: 'Nhân sự',
  organizationUnitType: 'Loại đơn vị',
  children: 'Đơn vị phụ thuộc',
  directUnder: 'Trực thuộc',
  inchargeService: 'Dịch vụ phụ trách',
  headAndDeputyNote: 'Trưởng và phó phòng là đối tượng mặc định nhìn thấy tất cả các phiếu thuộc phòng đội.'
};
