import { Button, Form, Input, Modal } from 'antd';
import { FormItem } from 'components/common';
import { validateMessages } from 'messages';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { CreateAgentMapDto } from 'types';

type AgentMapModalInfoProps = {
  handleSubmit: (value: AgentMapFormInfoType) => void;
};

export type AgentMapFormInfoType = Pick<CreateAgentMapDto, 'name'>;

export type AgentMapModalInfoRef = {
  setFieldsValue: (value: AgentMapFormInfoType) => void;
  setOpenInfo: (value: boolean) => void;
};

const AgentMapModalInfo = forwardRef<AgentMapModalInfoRef, AgentMapModalInfoProps>(({ handleSubmit }, ref) => {
  useImperativeHandle(ref, () => ({
    setFieldsValue,
    setOpenInfo
  }));
  const [openInfo, setOpenInfo] = useState(false);

  const [form] = Form.useForm<AgentMapFormInfoType>();

  const setFieldsValue = (values: AgentMapFormInfoType) => {
    form.setFieldsValue(values);
  };

  const onSubmit = (values: AgentMapFormInfoType) => {
    handleSubmit(values);
    setOpenInfo(false);
  };
  return (
    <>
      <Modal
        width={568}
        title={agentMapsMessages.mapName}
        open={openInfo}
        onCancel={() => {
          setOpenInfo(false);
        }}
        footer={
          <Button
            onClick={() => {
              form.submit();
            }}
            type='primary'
          >
            {agentMapsMessages.save}
          </Button>
        }
        destroyOnClose
      >
        <Form
          labelAlign='right'
          scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
          form={form}
          onFinish={onSubmit}
          labelCol={{
            flex: '180px'
          }}
          validateMessages={validateMessages}
        >
          <FormItem.FloatLabel<AgentMapFormInfoType>
            label={agentMapsMessages.mapName}
            name='name'
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem.FloatLabel>
        </Form>
      </Modal>
    </>
  );
});

export default AgentMapModalInfo;
