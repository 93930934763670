import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateApiSharingPartnerDto,
  FindAllApiSharingPartnerDto,
  ResponseDto,
  ResponsePagingDto,
  UpdateApiSharingPartnerDto,
  ApiSharingPartnerDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const apiSharingPartnerApi = createApi({
  reducerPath: 'apiSharingPartnerApi',
  tagTypes: ['api_sharing_partners', 'api_sharing_partners_detail'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getApiSharingPartners: builder.query<ResponsePagingDto<ApiSharingPartnerDto>, FindAllApiSharingPartnerDto>({
      query: (params) => ({
        url: '/api_sharing_partners',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ apiSharingPartnerId }) => ({
              type: 'api_sharing_partners',
              id: apiSharingPartnerId
            }))
          : ['api_sharing_partners']
    }),

    getApiSharingPartnerDetail: builder.query<ResponseDto<ApiSharingPartnerDto>, number>({
      query: (apiSharingPartnerId) => ({
        url: `/api_sharing_partners/${apiSharingPartnerId}`,
        method: 'get'
      }),
      providesTags: (result) =>
        result?.data ? [{ type: 'api_sharing_partners_detail', id: result.data.apiSharingPartnerId }] : []
    }),

    createApiSharingPartner: builder.mutation<ResponseDto<ApiSharingPartnerDto>, CreateApiSharingPartnerDto>({
      query: (newSchedule) => ({
        url: '/api_sharing_partners',
        method: 'post',
        data: newSchedule
      }),
      invalidatesTags: ['api_sharing_partners']
    }),

    updateApiSharingPartner: builder.mutation<ResponseDto<ApiSharingPartnerDto>, UpdateApiSharingPartnerDto>({
      query: ({ apiSharingPartnerId, ...updateSchedule }) => ({
        url: `/api_sharing_partners/${apiSharingPartnerId}`,
        method: 'patch',
        data: updateSchedule
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'api_sharing_partners', id: param.apiSharingPartnerId },
        { type: 'api_sharing_partners_detail', id: param.apiSharingPartnerId }
      ]
    }),

    deleteApiSharingPartner: builder.mutation<ResponseDto<null>, number>({
      query: (apiSharingPartnerId) => ({
        url: `/api_sharing_partners/${apiSharingPartnerId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'api_sharing_partners', id: param },
        { type: 'api_sharing_partners_detail', id: param }
      ]
    })
  })
});

export const {
  useGetApiSharingPartnersQuery,
  useGetApiSharingPartnerDetailQuery,
  useCreateApiSharingPartnerMutation,
  useUpdateApiSharingPartnerMutation,
  useDeleteApiSharingPartnerMutation
} = apiSharingPartnerApi;
