import { MenuProps } from 'antd';
import { CustomerSearchIcon, EditFormIcon, HeadPhoneIcon, ManagementIcon, ReportIcon, SystemIcon } from 'assets';
import { sidebarMenuMessages } from 'messages/sidebar-menu.messages';
import { ReactNode } from 'react';
import { ROUTE } from 'routes/constants';
import { PERMISSION, SIDEBAR_MENU_KEY } from 'utils/enums';

export type MenuItemType = Required<MenuProps>['items'][number] & {
  key?: string;
  href?: string;
  label?: string | ReactNode;
  icon?: ReactNode | string;
  children?: MenuItemType[];
  permission?: number | number[];
};
export const MENU_LIST_ITEM: MenuItemType[] = [
  {
    key: SIDEBAR_MENU_KEY.CUSTOMER_LOOKUP,
    label: sidebarMenuMessages.customerLookup,
    icon: <CustomerSearchIcon />,
    href: ROUTE.CUSTOMER_LOOKUP,
    permission: PERMISSION.ACCESS_CUSTOMER_LOOKUP
  },
  {
    key: SIDEBAR_MENU_KEY.REQUEST_FORM,
    label: sidebarMenuMessages.requestForm,
    icon: <EditFormIcon />,
    permission: PERMISSION.ACCESS_TICKET
  },
  {
    key: SIDEBAR_MENU_KEY.CALL_CENTER_MANAGEMENT,
    label: sidebarMenuMessages.callCenterManagement,
    icon: <HeadPhoneIcon />,
    permission: PERMISSION.ACCESS_MANAGE_DTV
  },
  {
    key: SIDEBAR_MENU_KEY.REPORTS,
    label: sidebarMenuMessages.reports,
    icon: <ReportIcon />,
    permission: PERMISSION.ACCESS_REPORT
  },
  {
    key: SIDEBAR_MENU_KEY.MANAGEMENT,
    label: sidebarMenuMessages.management,
    icon: <ManagementIcon />,
    permission: PERMISSION.ACCESS_MANAGEMENT,
    children: [
      {
        key: SIDEBAR_MENU_KEY.INTERNAL_ANNOUNCEMENTS,
        label: sidebarMenuMessages.internalAnnouncements,
        href: ROUTE.INTERNAL_ANNOUNCEMENTS,
        permission: PERMISSION.READ_INTERNAL_ANNOUNCEMENT
      },
      {
        key: SIDEBAR_MENU_KEY.VIP_CUSTOMERS,
        label: sidebarMenuMessages.vipCustomers,
        href: ROUTE.VIP_CUSTOMERS,
        permission: PERMISSION.READ_VIP_CUSTOMER
      },
      {
        key: SIDEBAR_MENU_KEY.AGENT_MAPS,
        label: sidebarMenuMessages.agentMaps,
        href: ROUTE.AGENT_MAPS,
        permission: PERMISSION.READ_AGENT_MAP
      },
      {
        key: SIDEBAR_MENU_KEY.AGENT_STATUS,
        label: sidebarMenuMessages.agentStatus,
        href: ROUTE.REASONS,
        permission: PERMISSION.READ_REASON
      },
      {
        key: SIDEBAR_MENU_KEY.WORKING_SCHEDULES,
        label: sidebarMenuMessages.workingSchedules,
        href: ROUTE.WORKING_SCHEDULES,
        permission: PERMISSION.READ_WORKING_SCHEDULE
      },
      {
        key: SIDEBAR_MENU_KEY.SHIFTS,
        label: sidebarMenuMessages.agentShift,
        href: ROUTE.SHIFTS,
        permission: PERMISSION.READ_SHIFT
      },
      {
        key: SIDEBAR_MENU_KEY.SERVICE_INSTRUCTIONS,
        label: sidebarMenuMessages.serviceInstructions,
        href: ROUTE.SERVICE_INSTRUCTIONS,
        permission: PERMISSION.READ_SERVICE_INSTRUCTION
      },
      {
        key: SIDEBAR_MENU_KEY.CUSTOMER_SUPPORT_INFORMATION,
        label: sidebarMenuMessages.customerSupportInformation,
        href: ROUTE.CUSTOMER_SUPPORT_INFORMATION,
        permission: PERMISSION.READ_CUSTOMER_SUPPORT_INFORMATION
      },
      {
        key: SIDEBAR_MENU_KEY.FREQUENTLY_ASKED_QUESTIONS,
        label: sidebarMenuMessages.frequentlyAskedQuestions,
        href: ROUTE.FREQUENTLY_ASKED_QUESTION,
        permission: PERMISSION.READ_FREQUENTLY_ASKED_QUESTION
      },
      {
        key: SIDEBAR_MENU_KEY.FANPAGE_CONNECTION,
        label: sidebarMenuMessages.fanpageConnection
      },
      {
        key: SIDEBAR_MENU_KEY.TICKET_CANCEL_REASONS,
        label: sidebarMenuMessages.ticketCancelReasons,
        href: ROUTE.TICKET_CANCEL_REASONS,
        permission: PERMISSION.READ_TICKET_CANCEL_REASON
      }
    ]
  },
  {
    type: 'divider'
  },
  {
    key: SIDEBAR_MENU_KEY.SYSTEM,
    label: sidebarMenuMessages.system,
    icon: <SystemIcon />,
    permission: PERMISSION.ACCESS_SYSTEM,
    children: [
      {
        key: SIDEBAR_MENU_KEY.USERS,
        label: sidebarMenuMessages.users,
        href: ROUTE.USERS,
        permission: PERMISSION.READ_USER
      },
      {
        key: SIDEBAR_MENU_KEY.USER_GROUPS,
        label: sidebarMenuMessages.userGroups,
        href: ROUTE.USER_GROUPS,
        permission: PERMISSION.READ_USER_GROUP
      },
      {
        key: SIDEBAR_MENU_KEY.PERMISSIONS,
        label: sidebarMenuMessages.permissions,
        href: ROUTE.PERMISSIONS,
        permission: PERMISSION.UPDATE_PERMISSION
      },
      {
        key: SIDEBAR_MENU_KEY.ORGANIZATION_UNIT,
        label: sidebarMenuMessages.organizationUnit,
        href: ROUTE.ORGANIZATION_UNITS,
        permission: PERMISSION.READ_ORGANIZATION_UNIT
      },
      {
        key: SIDEBAR_MENU_KEY.SERVICE_TYPES,
        label: sidebarMenuMessages.serviceTypes,
        href: ROUTE.SERVICE_TYPES,
        permission: PERMISSION.READ_SERVICE_TYPE
      },
      {
        key: SIDEBAR_MENU_KEY.TICKET_SAMPLES,
        label: sidebarMenuMessages.ticketSamples,
        href: ROUTE.TICKET_SAMPLES,
        permission: PERMISSION.READ_TICKET_SAMPLE
      },
      {
        key: SIDEBAR_MENU_KEY.SYSTEM_CONFIGS,
        label: sidebarMenuMessages.systemConfigs,
        href: ROUTE.RATING_SYSTEM,
        permission: PERMISSION.UPDATE_SYSTEM_CONFIG
      },
      {
        key: SIDEBAR_MENU_KEY.API_SHARING_PARTNERS,
        label: sidebarMenuMessages.apiSharingPartners,
        href: ROUTE.API_SHARING_PARTNERS,
        permission: PERMISSION.READ_API_SHARING_PARTNER
      }
    ]
  },
  {
    type: 'divider'
  },
  {
    key: SIDEBAR_MENU_KEY.PROFILE,
    label: sidebarMenuMessages.profile,
    icon: 'avatar',
    href: ROUTE.PROFILE
  }
];
