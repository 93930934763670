import { Col, Radio, Row, Typography } from 'antd';
import { find } from 'lodash';
import { useState } from 'react';
import FilterOrigin, { FilterPopoverOptionItem } from './Filter';

type FilterRadioProps = React.ComponentProps<typeof FilterOrigin> & {
  options: FilterPopoverOptionItem[];
};
const FilterRadio = ({ value, options, children, icon, title, ...props }: FilterRadioProps) => {
  const [valueTemp, setValueTemp] = useState(value);
  const content = (
    <Radio.Group
      onChange={(e) => {
        setValueTemp(e.target.value);
      }}
      value={valueTemp}
      className='no-scrollbar -mx-4 max-h-[300px] w-[calc(100%+32px)] overflow-auto'
    >
      <Row align='middle' gutter={0}>
        {options.map((o) => (
          <Col span={24} key={o.value?.toString() || ''} className='flex h-11 items-center hover:bg-hoverColor1'>
            <Radio className='px-4' value={o.value}>
              {o.label}
            </Radio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
  const childrenLabel = (
    <>
      {icon}
      {value && value?.length > 0 ? (
        <Typography.Paragraph
          className='mb-0 max-w-[175px] text-sm'
          ellipsis={{
            rows: 1,
            tooltip: true
          }}
        >
          {value.map((o: any) => find(options, { value: o })?.label).join(', ')}
        </Typography.Paragraph>
      ) : (
        title
      )}
    </>
  );
  return (
    <FilterOrigin
      {...props}
      title={title}
      content={content}
      valueTemp={valueTemp}
      clearFilter={() => setValueTemp(undefined)}
      value={value}
      setValueTemp={setValueTemp}
    >
      {childrenLabel}
    </FilterOrigin>
  );
};

export default FilterRadio;
