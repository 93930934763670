import { usePaging } from 'hooks/usePaging';
import { useGetElectricalRecordingSchedulesQuery } from 'services';
import { FindAllElectricalRecordingSchedulesDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useElectricalRecordingSchedulePaging(
  params: Omit<FindAllElectricalRecordingSchedulesDto, 'pageIndex' | 'pageSize'>
) {
  const { month, year, numberOfPeriods, sessionNumber } = params;
  const { pageIndex, resetPage, changePage } = usePaging(params);
  const {
    data: electricalRecordingScheduleResponse,
    error,
    isLoading,
    isFetching
  } = useGetElectricalRecordingSchedulesQuery({
    pageIndex: pageIndex,
    pageSize,
    month,
    year,
    numberOfPeriods,
    sessionNumber
  });
  const handleChangePage = changePage;

  return {
    electricalRecorindSchedules: electricalRecordingScheduleResponse?.data.rows,
    count: electricalRecordingScheduleResponse ? electricalRecordingScheduleResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage,
    resetPage
  };
}
