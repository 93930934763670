import { Button, Result } from 'antd';
import { useProfile } from 'hooks';
import { messages } from 'messages';
import { PropsWithChildren } from 'react';
import { ROUTE } from 'routes/constants';
import { checkPermissions, checkEveryPermissions } from 'utils/check-permission';

type CheckPermissionLayoutProps = PropsWithChildren & {
  permission: number | number[];
};

function CheckPermissionLayout({ permission, children }: CheckPermissionLayoutProps) {
  const { userPermissionIds } = useProfile();

  const isAllow =
    typeof permission === 'number'
      ? checkPermissions(userPermissionIds, permission)
      : checkEveryPermissions(userPermissionIds, permission);

  return (
    <>
      {isAllow ? (
        children
      ) : (
        <Result
          status='403'
          title='403'
          subTitle={messages.notAllowToAccess}
          extra={
            <Button
              type='primary'
              onClick={() => {
                window.location.href = ROUTE.HOME;
              }}
            >
              {messages.home}
            </Button>
          }
        />
      )}
    </>
  );
}

export default CheckPermissionLayout;
