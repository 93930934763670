import { Spin, Pagination, Button } from 'antd';
import { ExcelFileIcon } from 'assets';
import { InputSearchV2 } from 'components/common';
import Excel from 'components/common/excel/Excel';
import { useFile } from 'hooks/files';
import { messages, sidebarMenuMessages } from 'messages';
import { useEffect, useState } from 'react';
import { useGetCustomerSupportInformationFormatContentQuery } from 'services';
import { twMerge } from 'tailwind-merge';
import { addIndexColumn, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';

type CustomerSupportInformationExcelProps = {
  customerSupportInformationId?: number;
  minWidth?: number;
  maxWidth?: number;
  height?: number;
  searchClassName?: string;
  showSearch?: boolean;
  onLoadData?: (data: (string | number)[][]) => void;
  showExport?: boolean;
  title?: string;
  header?: (string | number)[];
  fileExcelId?: number;
};

const CustomerSupportInformationExcel = ({
  customerSupportInformationId,
  minWidth,
  maxWidth,
  searchClassName,
  height,
  onLoadData,
  showSearch = true,
  showExport = true,
  title = sidebarMenuMessages.customerSupportInformation,
  header,
  fileExcelId
}: CustomerSupportInformationExcelProps) => {
  const [keyword, setKeyword] = useState('');
  const [excelPage, setExcelPage] = useState(DEFAULT_PAGE_INDEX);
  const [rowZero, setRowZero] = useState<(string | number)[]>(header ?? []);
  const {
    data: formatContent,
    isLoading: isLoadingContent,
    isFetching: isFetchingContent
  } = useGetCustomerSupportInformationFormatContentQuery(
    {
      customerSupportInformationId: customerSupportInformationId!,
      keyword: keyword,
      pageIndex: excelPage,
      pageSize: DEFAULT_PAGE_SIZE
    },
    {
      skip: !customerSupportInformationId,
      refetchOnMountOrArgChange: true
    }
  );
  const { file } = useFile(fileExcelId);

  useEffect(() => {
    setExcelPage(DEFAULT_PAGE_INDEX);
    setKeyword('');
  }, [customerSupportInformationId]);

  useEffect(() => {
    if (formatContent?.data.rows) {
      if (!header) {
        let excelData: (string | number)[][] | undefined = [
          ...(excelPage === 1 ? [] : [rowZero]),
          ...(formatContent?.data.rows || [])
        ];
        if (excelPage === 1 && !keyword) {
          excelData = addIndexColumn(excelData);
          if (excelData) setRowZero(excelData[0]);
        }
        if (excelData) onLoadData?.(excelData);
      } else {
        let excelData = formatContent?.data.rows;
        onLoadData?.(excelData);
      }
    }
  }, [formatContent]);

  useEffect(() => {
    if (header) {
      setRowZero(header);
    }
  }, [header]);

  const heightTable = height ? height - (showSearch ? 164 : 0) : undefined;

  return (
    <div className='w-full'>
      {showSearch && (
        <div
          className={twMerge('relative left-1/2 mb-5 flex h-fit -translate-x-1/2 items-center rounded-lg')}
          style={{ width: minWidth }}
        >
          {showSearch && (
            <InputSearchV2
              placeholder={messages.search}
              className={twMerge('rounded-lg', searchClassName)}
              onChange={setKeyword}
            />
          )}
          {showExport && (
            <Button
              type='link'
              className='ml-auto h-10 font-normal text-colorTextBase'
              icon={<ExcelFileIcon className='h-4 w-4' />}
              href={file?.url}
              disabled={!file?.url}
              download={file?.objectName}
            >
              {messages.exportExcel}
            </Button>
          )}
        </div>
      )}
      <Spin spinning={isLoadingContent || isFetchingContent}>
        <Excel
          height={heightTable}
          minWidth={minWidth}
          maxWidth={maxWidth}
          data={formatContent?.data.rows}
          pageIndex={excelPage - 1}
          titleRows={(excelPage !== 1 || keyword.length) && rowZero ? rowZero : undefined}
        />
      </Spin>
      <Pagination
        className='mt-4 text-end'
        current={excelPage}
        total={formatContent?.data.count}
        onChange={setExcelPage}
        pageSize={DEFAULT_PAGE_SIZE}
        showSizeChanger={false}
      />
    </div>
  );
};

export default CustomerSupportInformationExcel;
