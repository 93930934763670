import { createApi } from '@reduxjs/toolkit/query/react';
import { ResponsePagingDto } from 'types';
import { AdministrativeUnitDto } from 'types/dto/administrativeUnit';
import { FindAllAdministrativeUnitDto } from 'types/dto/administrativeUnit/find-all-administrativeUnit.dto';
import { FindAllWardByDistricttDto } from 'types/dto/administrativeUnit/find-all-ward-by-district.dto';
import { WardByDistrictDto } from 'types/dto/administrativeUnit/ward-by-district.dto';
import axiosBaseQuery from 'utils/base-api';

export const administrativeUnitsApi = createApi({
    reducerPath: 'administrativeUnitsApi',
    tagTypes: ['administrativeUnits', 'administrativeUnits_detail','administrativeUnits_ward'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        getDistricts: builder.query<ResponsePagingDto<AdministrativeUnitDto>, FindAllAdministrativeUnitDto>({
            query: (params) => ({
                url: '/administrative_units/districts',
                method: 'get',
                params
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(({administrativeUnitId}) => ({
                        type: 'administrativeUnits',
                        id: administrativeUnitId
                     }))
                    : ['administrativeUnits']
        }),
        getWardsByDistrictId: builder.query<ResponsePagingDto<WardByDistrictDto>, FindAllWardByDistricttDto>({
            query: ({districtId, ...params}) => ({
                url: `/administrative_units/wards/${districtId}`,
                method: 'get',
                params
            }),
            serializeQueryArgs:({endpointName}) => endpointName,
            merge: (currentCache, newItems, { arg }) => {
                if (arg.pageIndex !==1) {
                    currentCache.data.rows.push(...newItems.data.rows);
                } else currentCache.data.rows = newItems.data.rows;
            },
            forceRefetch({ currentArg, previousArg}) {
                return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
            },
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(({administrativeUnitId, districtId}) => ({
                        type: 'administrativeUnits_ward',
                        id: `${administrativeUnitId}-${districtId}`
                    }))
                    : ['administrativeUnits_ward']
        })
    })
})

export const {
    useGetDistrictsQuery,
    useGetWardsByDistrictIdQuery,
} = administrativeUnitsApi