import { usePaging } from 'hooks/usePaging';
import { useGetElectricalPaymentLocationsQuery } from 'services';
import { FindAllElectricityPaymentLocationsDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useElectricityPaymentLocationPaging(
  params: Omit<FindAllElectricityPaymentLocationsDto, 'pageIndex' | 'pageSize'>
) {
  const { district, category } = params;
  const { pageIndex, resetPage, changePage } = usePaging(params);
  const {
    data: electricityPaymentLocationResponse,
    error,
    isLoading,
    isFetching
  } = useGetElectricalPaymentLocationsQuery({
    pageIndex: pageIndex,
    pageSize,
    district,
    category
  });
  const handleChangePage = changePage;

  return {
    electricityPaymentLocations: electricityPaymentLocationResponse?.data.rows,
    count: electricityPaymentLocationResponse ? electricityPaymentLocationResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage,
    resetPage
  };
}
