export const workingSchedulesMessages = {
  workingScheduleId: 'Mã quản lý',
  workingScheduleType: 'Loại ngày',
  applyDate: 'Ngày',
  description: 'Diễn giải',
  create: 'Tạo mới',
  update: 'Cập nhật',
  delete: 'Xóa ngày',
  confirmDelete: 'Bạn có chắc chắn muốn xóa ngày này không?'
};
