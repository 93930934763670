import { createApi } from '@reduxjs/toolkit/query/react';
import { RcFile } from 'antd/es/upload';
import {
  CreateCustomerSupportInformationDto,
  CustomerSupportInformationDto,
  ElectricalRecordingScheduleDto,
  ElectricityPaymentLocationDto,
  FindAllCustomerSupportInformationDto,
  FindAllCustomerSupportInformationFormatContentDto,
  FindAllElectricalRecordingSchedulesDto,
  FindAllElectricityPaymentLocationCategoriesDto,
  FindAllElectricityPaymentLocationsDto,
  ResponseDto,
  ResponsePagingDto,
  UpdateCustomerSupportInformationDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const customerSupportInformationApi = createApi({
  reducerPath: 'customerSupportInformationApi',
  tagTypes: [
    'customer_support_information',
    'customer_support_information_detail',
    'customer_support_information_format_content',
    'electricity_recording_schedule',
    'electricity_payment_location',
    'electricity_payment_location_categories'
  ],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCustomerSupportInformation: builder.query<
      ResponsePagingDto<CustomerSupportInformationDto>,
      FindAllCustomerSupportInformationDto
    >({
      query: (params) => ({
        url: '/customer_support_information',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerSupportInformationId }) => ({
              type: 'customer_support_information',
              id: customerSupportInformationId
            }))
          : ['customer_support_information']
    }),

    getCustomerSupportInformationActive: builder.query<
      ResponsePagingDto<Omit<CustomerSupportInformationDto, 'content'>>,
      void
    >({
      query: (params) => ({
        url: '/customer_support_information/active',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerSupportInformationId }) => ({
              type: 'customer_support_information',
              id: customerSupportInformationId
            }))
          : ['customer_support_information']
    }),
    getCustomerSupportInformationDetail: builder.query<ResponseDto<CustomerSupportInformationDto>, number>({
      query: (customerSupportInformationId) => ({
        url: `/customer_support_information/${customerSupportInformationId}`,
        method: 'get'
      }),
      providesTags: (result) =>
        result?.data ? [{ type: 'customer_support_information', id: result.data.customerSupportInformationId }] : []
    }),
    getCustomerSupportInformationActiveDetail: builder.query<ResponseDto<CustomerSupportInformationDto>, number>({
      query: (customerSupportInformationId) => ({
        url: `/customer_support_information/active/${customerSupportInformationId}`,
        method: 'get'
      }),
      providesTags: (result) =>
        result?.data ? [{ type: 'customer_support_information', id: result.data.customerSupportInformationId }] : []
    }),

    getCustomerSupportInformationFormatContent: builder.query<
      ResponsePagingDto<string[]>,
      FindAllCustomerSupportInformationFormatContentDto
    >({
      query: ({ customerSupportInformationId, ...params }) => ({
        url: `/customer_support_information/${customerSupportInformationId}/content`,
        method: 'get',
        params
      }),
      providesTags: (result, _, params) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(() => ({
              type: 'customer_support_information_format_content',
              id: params.customerSupportInformationId
            }))
          : ['customer_support_information_format_content']
    }),

    getCustomerSupportInformationFormatContentActive: builder.query<
      ResponsePagingDto<string[]>,
      FindAllCustomerSupportInformationFormatContentDto
    >({
      query: ({ customerSupportInformationId, ...params }) => ({
        url: `/customer_support_information/active/${customerSupportInformationId}/content`,
        method: 'get',
        params
      }),
      providesTags: (result, _, params) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(() => ({
              type: 'customer_support_information_format_content',
              id: params.customerSupportInformationId
            }))
          : ['customer_support_information_format_content']
    }),

    createCustomerSupportInformation: builder.mutation<
      ResponseDto<CustomerSupportInformationDto>,
      CreateCustomerSupportInformationDto
    >({
      query: (newCustomerSupportInformation) => ({
        url: '/customer_support_information',
        method: 'post',
        data: newCustomerSupportInformation
      }),
      invalidatesTags: ['customer_support_information']
    }),
    updateCustomerSupportInformation: builder.mutation<
      ResponseDto<CustomerSupportInformationDto>,
      UpdateCustomerSupportInformationDto
    >({
      query: ({ customerSupportInformationId, ...updateCustomerSupportInformation }) => ({
        url: `/customer_support_information/${customerSupportInformationId}`,
        method: 'patch',
        data: updateCustomerSupportInformation
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'customer_support_information', id: param.customerSupportInformationId },
        { type: 'customer_support_information_detail', id: param.customerSupportInformationId }
      ]
    }),
    deleteCustomerSupportInformation: builder.mutation<void, number>({
      query: (customerSupportInformationId) => ({
        url: `/customer_support_information/${customerSupportInformationId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'customer_support_information', id: param },
        { type: 'customer_support_information_detail', id: param }
      ]
    }),
    uploadElectricalRecordingSchedules: builder.mutation<ResponseDto<void>, RcFile>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: '/customer_support_information/electrical_recording_schedules',
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        };
      },
      invalidatesTags: ['electricity_recording_schedule']
    }),
    uploadElectricityPaymentLocations: builder.mutation<ResponseDto<void>, RcFile>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: '/customer_support_information/electricity_payment_locations',
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        };
      },
      invalidatesTags: ['electricity_payment_location']
    }),
    getElectricalRecordingSchedules: builder.query<
      ResponsePagingDto<ElectricalRecordingScheduleDto>,
      FindAllElectricalRecordingSchedulesDto
    >({
      query: (params) => ({
        url: '/customer_support_information/electrical_recording_schedules',
        method: 'get',
        params
      }),
      providesTags: ['electricity_recording_schedule']
    }),
    getElectricalPaymentLocations: builder.query<
      ResponsePagingDto<ElectricityPaymentLocationDto>,
      FindAllElectricityPaymentLocationsDto
    >({
      query: (params) => ({
        url: '/customer_support_information/electricity_payment_locations',
        method: 'get',
        params
      }),
      providesTags: ['electricity_payment_location']
    }),
    getElectricalPaymentLocationCategories: builder.query<
      ResponsePagingDto<string>,
      FindAllElectricityPaymentLocationCategoriesDto
    >({
      query: (params) => ({
        url: '/customer_support_information/electricity_payment_locations/categories',
        method: 'get',
        params
      }),
      providesTags: ['electricity_payment_location_categories']
    })
  })
});

export const {
  useGetCustomerSupportInformationActiveQuery,
  useGetCustomerSupportInformationQuery,
  useGetCustomerSupportInformationDetailQuery,
  useGetCustomerSupportInformationActiveDetailQuery,
  useLazyGetCustomerSupportInformationDetailQuery,
  useCreateCustomerSupportInformationMutation,
  useUpdateCustomerSupportInformationMutation,
  useDeleteCustomerSupportInformationMutation,
  useGetCustomerSupportInformationFormatContentQuery,
  useGetCustomerSupportInformationFormatContentActiveQuery,
  useLazyGetCustomerSupportInformationFormatContentQuery,
  useGetElectricalPaymentLocationCategoriesQuery,
  useGetElectricalPaymentLocationsQuery,
  useGetElectricalRecordingSchedulesQuery,
  useUploadElectricalRecordingSchedulesMutation,
  useUploadElectricityPaymentLocationsMutation
} = customerSupportInformationApi;
