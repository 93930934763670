import { Checkbox, Form, FormInstance, Input, Spin } from 'antd';
import { CheckTwoToneCircledIcon } from 'assets';
import { FilterStatus, FormItem, message } from 'components/common';
import { apiSharingPartnersMessages, messages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateApiSharingPartnerMutation,
  useGetApiSharingPartnerDetailQuery,
  useUpdateApiSharingPartnerMutation
} from 'services';
import { CreateApiSharingPartnerDto } from 'types';
import {
  apiSharingPartnerValidationRules,
  createApiSharingPartnerInitialValues,
  updateApiSharingPartnerInitialValues
} from 'utils';

export type ApiSharingPartnerFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  apiSharingPartnerId?: number;
};

export type ApiSharingPartnerFormRefProps = {
  form: FormInstance<ApiSharingPartnerFormType>;
  isLoading: boolean;
};

export type ApiSharingPartnerFormType = CreateApiSharingPartnerDto & {};
const ApiSharingPartnerForm = forwardRef<ApiSharingPartnerFormRefProps, ApiSharingPartnerFormProps>(
  ({ onChangeLoading, onCreateSuccess, apiSharingPartnerId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { data: apiSharingPartner, isLoading: isLoadingDetail } = useGetApiSharingPartnerDetailQuery(
      apiSharingPartnerId!,
      {
        skip: !apiSharingPartnerId,
        refetchOnMountOrArgChange: true
      }
    );

    useEffect(() => {
      if (apiSharingPartner?.data && apiSharingPartnerId) {
        form.setFieldsValue({
          apiKey: apiSharingPartner.data.apiKey,
          code: apiSharingPartner.data.code,
          description: apiSharingPartner.data.description,
          isActive: apiSharingPartner.data.isActive,
          name: apiSharingPartner.data.name,
          permissions: apiSharingPartner.data.permissions
        });
      }
    }, [apiSharingPartner, apiSharingPartnerId]);

    const [form] = Form.useForm<ApiSharingPartnerFormType>();

    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateApiSharingPartnerMutation();
    const [onCreate, { isLoading: isLoadingCreate }] = useCreateApiSharingPartnerMutation();

    const onFinish = ({ ...values }: ApiSharingPartnerFormType) => {
      const data: CreateApiSharingPartnerDto = {
        ...values
      };

      if (!apiSharingPartnerId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          apiSharingPartnerId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate || isLoadingCreate);
      }
    }, [onChangeLoading, isLoadingUpdate, isLoadingCreate]);

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='apiSharingPartnerForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={
          apiSharingPartnerId ? updateApiSharingPartnerInitialValues : createApiSharingPartnerInitialValues
        }
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.name}
            name='name'
            rules={apiSharingPartnerValidationRules.name}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.description}
            name='description'
            rules={apiSharingPartnerValidationRules.description}
          >
            <Input.TextArea />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.code}
            name='code'
            rules={apiSharingPartnerValidationRules.code}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.apiKey}
            name='apiKey'
            rules={apiSharingPartnerValidationRules.apiKey}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.permissions}
            name='permissions'
            rules={apiSharingPartnerValidationRules.permissions}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.status}
            name='isActive'
            rules={apiSharingPartnerValidationRules.isActive}
            hiddenLabel
            valuePropName='checked'
          >
            <Checkbox>{messages.statusEnum.active}</Checkbox>
          </FormItem>
        </Spin>
      </Form>
    );
  }
);
export default ApiSharingPartnerForm;
