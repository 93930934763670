export const agentMapsMessages = {
  addSeat: 'Thêm chỗ ngồi',
  delete: 'Xóa',
  deleteSeat: 'Xóa chỗ ngồi',
  deleteObject: 'Xóa đối tượng',
  addWall: 'Thêm tường',
  addDoor: 'Thêm cửa',
  save: 'Lưu',
  name: 'Tên sơ đồ',
  errorSeats: 'Vui lòng nhập kiểm tra IP và SỐ MÁY chỗ ngồi',
  requiredSeats: 'Vui lòng thêm chỗ ngồi',
  drawGrid: 'Đang tải bản đồ',
  editInfo: 'Sửa thông tin',
  seatName: 'Số máy',
  seatIp: 'Địa chỉ IP',
  seatNameDuplicate: 'Số máy đã tồn tại trong sơ đồ',
  seatIpDuplicate: 'Địa chỉ IP đã tồn tại trong sơ đồ',
  addSingleDoor: 'Thêm cửa đơn',
  addDoubleDoor: 'Thêm cửa đôi',
  addSlidingDoor: 'Thêm cửa kéo',
  newMap: 'Sơ đồ mới',
  copySuccess: 'Đã sao chép đối tượng ',
  seatNameTitle: (value: number | string) => `Máy ${value}`,
  deleteAgentMap: 'Xóa sơ đồ chỗ ngồi',
  confirmDelete: 'Bạn có chắc chắn muốn xóa sơ đồ này không?',
  seat: 'Chỗ ngồi',
  door: 'Cửa',
  mapName: 'Tên sơ đồ',
  update: 'Cập nhật sơ đồ chỗ ngồi',
  create: 'Tạo sơ đồ chỗ ngồi'
};
