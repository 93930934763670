export const rolesMessages = {
  name: 'Tên nhóm quyền',
  permissions: 'Quyền',
  code: 'Mã quản lý',
  description: 'Mô tả',
  update: 'Cập nhật nhóm quyền',
  create: 'Tạo nhóm quyền',
  title: 'Nhóm quyền',
  edit: 'Sửa',
  rolePermissions: 'Quyền trong nhóm',
  general: 'Thông tin chung',
  editPermission: 'Phân quyền'
};
